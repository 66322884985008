var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"package-details"},[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('unicon',{staticClass:"mr-1",staticStyle:{"fill":"#6e6b7b"},attrs:{"name":"users-alt"}}),_c('h4',{staticClass:"m-0"},[_vm._v("معلومات الحزمة")])],1),_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.toggleVal(_vm.dtoPackage.isHidden)}},model:{value:(_vm.changeValCheck),callback:function ($$v) {_vm.changeValCheck=$$v},expression:"changeValCheck"}},[_c('b',[_vm._v(_vm._s(_vm.dtoPackage.isHidden ? "الحزمة فعالة" : "الحزمة مخفية"))])])],1)]},proxy:true}])},[_c('validationObserver',{ref:"packageForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"3"}},[_c('ek-input-text',{attrs:{"name":"اسم الحزمة","label":"اسم الحزمة","rules":[
                                {
                                    type: 'required',
                                    message: 'اسم الحزمة مطلوب',
                                } ]},model:{value:(_vm.dtoPackage.name),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "name", $$v)},expression:"dtoPackage.name"}})],1),_c('b-col',{attrs:{"lg":"4","md":"3"}},[_c('ek-input-text',{attrs:{"name":"مدة الحزمة","label":"مدة الحزمة بالأشهر","rules":[
                                {
                                    type: 'required',
                                    message: 'مدة الحزمة مطلوبة',
                                } ]},model:{value:(_vm.dtoPackage.expiryPeriod),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "expiryPeriod", $$v)},expression:"dtoPackage.expiryPeriod"}})],1),_c('b-col',{attrs:{"lg":"4","md":"3"}},[_c('ek-input-select',{attrs:{"options":_vm.packageStatusType,"name":"نوع الحزمة","label":"نوع الحزمة","rules":[
                                {
                                    type: 'required',
                                    message: 'نوع الحزمة مطلوب',
                                } ]},model:{value:(_vm.dtoPackage.type),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "type", $$v)},expression:"dtoPackage.type"}})],1),_c('b-col',{attrs:{"lg":"4","md":"3"}},[_c('ek-input-textarea',{attrs:{"name":"وصف الحزمة","label":"وصف الحزمة","rules":[
                                {
                                    type: 'required',
                                    message: 'وصف الحزمة مطلوب',
                                } ]},model:{value:(_vm.dtoPackage.description),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "description", $$v)},expression:"dtoPackage.description"}})],1)],1)],1)],1)],1),_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('unicon',{staticClass:"mr-1",staticStyle:{"fill":"#6e6b7b"},attrs:{"name":"users-alt"}}),_c('h4',{staticClass:"m-0"},[_vm._v("محتويات الحزمة")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[(_vm.id)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.editPackage}},[_vm._v(" تعديل ")]):_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("اضافة")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-primary"},on:{"click":_vm.backBtn}},[_vm._v(" تراجع ")])],1),_c('div',[_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.delBtn(_vm.id)}}},[_vm._v(" حذف الحزمة ")])],1)])]},proxy:true}])},[(_vm.dtoPackage.type == 1)?_c('div',[_c('validationObserver',{ref:"e"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('ek-input-text',{attrs:{"name":"عدد المواد","label":"عدد المواد","rules":[
                                    {
                                        type: 'required',
                                        message: 'عدد المواد مطلوب',
                                    } ]},model:{value:(_vm.dtoPackage.subjectsLimit),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "subjectsLimit", $$v)},expression:"dtoPackage.subjectsLimit"}})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('ek-input-text',{attrs:{"name":"سعر الحزمة","label":"سعر الحزمة","rules":[
                                    {
                                        type: 'required',
                                        message: 'سعر الحزمة مطلوب',
                                    } ]},model:{value:(_vm.dtoPackage.price),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "price", $$v)},expression:"dtoPackage.price"}})],1)],1)],1)],1)],1):_c('div',[_c('validationObserver',{ref:"e"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('ek-input-text',{attrs:{"name":"سعر الحزمة","label":"سعر الحزمة","rules":[
                                    {
                                        type: 'required',
                                        message: 'سعر الحزمة مطلوب',
                                    } ]},model:{value:(_vm.dtoPackage.price),callback:function ($$v) {_vm.$set(_vm.dtoPackage, "price", $$v)},expression:"dtoPackage.price"}})],1)],1)],1)],1),_c('b-list-group',_vm._l((_vm.dtoPackage.subjectUnitsChapters),function(subject,index){return _c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("packageItem-" + (subject.subjectId))),expression:"`packageItem-${subject.subjectId}`"}],key:subject.subjectId},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"my-1",on:{"change":function($event){return _vm.toggleSubject($event, index)}},model:{value:(subject.selected),callback:function ($$v) {_vm.$set(subject, "selected", $$v)},expression:"subject.selected"}}),_c('h4',[_vm._v(_vm._s(subject.subjectName))])],1),_c('b-collapse',{attrs:{"id":("packageItem-" + (subject.subjectId)),"role":"tabpanel"}},[_c('b-list-group',_vm._l((subject.units),function(unit){return _c('b-list-group-item',{key:unit.id},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-5 py-1"},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.toggleUnit(
                                                $event,
                                                index,
                                                unit.id
                                            )}},model:{value:(unit.selected),callback:function ($$v) {_vm.$set(unit, "selected", $$v)},expression:"unit.selected"}},[_vm._v(" "+_vm._s(unit.name)+" ")]),_c('h5',{staticClass:"m-0"},[_c('strong',[_vm._v(_vm._s(unit.price)+" ل.س")])])],1),(
                                        unit.chapters &&
                                        unit.chapters.length > 0
                                    )?_c('b-list-group',_vm._l((unit.chapters),function(chapter){return _c('b-list-group-item',{key:chapter.id},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-5 py-1"},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.toggleChapter(
                                                        $event,
                                                        index,
                                                        unit.id,
                                                        chapter.id
                                                    )}},model:{value:(chapter.selected),callback:function ($$v) {_vm.$set(chapter, "selected", $$v)},expression:"chapter.selected"}},[_vm._v(" "+_vm._s(chapter.name)+" ")]),_c('h6',[_c('strong',[_vm._v(_vm._s(chapter.price)+" ل.س")])])],1)])}),1):_vm._e()],1)}),1)],1)],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }