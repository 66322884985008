<template>
    <div class="package-details">
        <b-card>
            <template
                #header
                class="d-flex justify-content-center align-items-center w-100"
            >
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات الحزمة</h4>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <b-form-checkbox
                        v-model="changeValCheck"
                        name="check-button"
                        switch
                        @change="toggleVal(dtoPackage.isHidden)"
                    >
                        <b>{{
                            dtoPackage.isHidden
                                ? "الحزمة فعالة"
                                : "الحزمة مخفية"
                        }}</b>
                    </b-form-checkbox>
                </div>
            </template>
            <validationObserver ref="packageForm">
                <b-form>
                    <b-row>
                        <b-col lg="4" md="3">
                            <ek-input-text
                                v-model="dtoPackage.name"
                                name="اسم الحزمة"
                                label="اسم الحزمة"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الحزمة مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col lg="4" md="3">
                            <ek-input-text
                                v-model="dtoPackage.expiryPeriod"
                                name="مدة الحزمة"
                                label="مدة الحزمة بالأشهر"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'مدة الحزمة مطلوبة',
                                    },
                                ]"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col lg="4" md="3">
                            <ek-input-select
                                :options="packageStatusType"
                                v-model="dtoPackage.type"
                                name="نوع الحزمة"
                                label="نوع الحزمة"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'نوع الحزمة مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-select>
                        </b-col>
                        <b-col lg="4" md="3">
                            <ek-input-textarea
                                v-model="dtoPackage.description"
                                name="وصف الحزمة"
                                label="وصف الحزمة"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'وصف الحزمة مطلوب',
                                    },
                                ]"
                            >
                            </ek-input-textarea>
                        </b-col>
                    </b-row>
                </b-form>
            </validationObserver>
        </b-card>
        <b-card>
            <template #header>
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">محتويات الحزمة</h4>
                </div>
            </template>
            <div v-if="dtoPackage.type == 1">
                <validationObserver ref="e">
                    <b-form>
                        <b-row>
                            <b-col lg="6">
                                <ek-input-text
                                    v-model="dtoPackage.subjectsLimit"
                                    name="عدد المواد"
                                    label="عدد المواد"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'عدد المواد مطلوب',
                                        },
                                    ]"
                                >
                                </ek-input-text>
                            </b-col>
                            <b-col lg="6">
                                <ek-input-text
                                    v-model="dtoPackage.price"
                                    name="سعر الحزمة"
                                    label="سعر الحزمة"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'سعر الحزمة مطلوب',
                                        },
                                    ]"
                                >
                                </ek-input-text>
                            </b-col>
                        </b-row>
                    </b-form>
                </validationObserver>
            </div>

            <div v-else>
                <validationObserver ref="e">
                    <b-form>
                        <b-row>
                          
                            <b-col lg="6">
                                <ek-input-text
                                    v-model="dtoPackage.price"
                                    name="سعر الحزمة"
                                    label="سعر الحزمة"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'سعر الحزمة مطلوب',
                                        },
                                    ]"
                                >
                                </ek-input-text>
                            </b-col>
                        </b-row>
                    </b-form>
                </validationObserver>
                <b-list-group>
                    <b-list-group-item
                        v-for="(
                            subject, index
                        ) in dtoPackage.subjectUnitsChapters"
                        :key="subject.subjectId"
                        v-b-toggle="`packageItem-${subject.subjectId}`"
                    >
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                class="my-1"
                                @change="toggleSubject($event, index)"
                                v-model="subject.selected"
                            ></b-form-checkbox>

                            <h4>{{ subject.subjectName }}</h4>
                        </div>

                        <b-collapse
                            :id="`packageItem-${subject.subjectId}`"
                            role="tabpanel"
                        >
                            <b-list-group>
                                <b-list-group-item
                                    v-for="unit in subject.units"
                                    :key="unit.id"
                                >
                                    <div
                                        class="d-flex justify-content-between align-items-center px-5 py-1"
                                    >
                                        <b-form-checkbox
                                            v-model="unit.selected"
                                            @change="
                                                toggleUnit(
                                                    $event,
                                                    index,
                                                    unit.id
                                                )
                                            "
                                        >
                                            {{ unit.name }}
                                        </b-form-checkbox>
                                        <h5 class="m-0">
                                            <strong
                                                >{{ unit.price }} ل.س</strong
                                            >
                                        </h5>
                                    </div>

                                    <b-list-group
                                        v-if="
                                            unit.chapters &&
                                            unit.chapters.length > 0
                                        "
                                    >
                                        <b-list-group-item
                                            v-for="chapter in unit.chapters"
                                            :key="chapter.id"
                                        >
                                            <div
                                                class="d-flex justify-content-between align-items-center px-5 py-1"
                                            >
                                                <b-form-checkbox
                                                    v-model="chapter.selected"
                                                    @change="
                                                        toggleChapter(
                                                            $event,
                                                            index,
                                                            unit.id,
                                                            chapter.id
                                                        )
                                                    "
                                                >
                                                    {{ chapter.name }}
                                                </b-form-checkbox>
                                                <h6>
                                                    <strong
                                                        >{{
                                                            chapter.price
                                                        }}
                                                        ل.س</strong
                                                    >
                                                </h6>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-list-group-item>
                            </b-list-group>
                        </b-collapse>
                    </b-list-group-item>
                </b-list-group>
            </div>

            <template #footer>
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <b-button
                            class="ml-1"
                            variant="primary"
                            @click="editPackage"
                            v-if="id"
                        >
                            تعديل
                        </b-button>
                        <b-button v-else variant="primary" @click="submit"
                            >اضافة</b-button
                        >
                        <b-button
                            class="ml-1"
                            variant="outline-primary"
                            @click="backBtn"
                        >
                            تراجع
                        </b-button>
                    </div>
                    <div>
                        <b-button
                            class="ml-1"
                            variant="outline-primary"
                            @click="delBtn(id)"
                        >
                            حذف الحزمة
                        </b-button>
                    </div>
                </div>
            </template>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: ["id"],
    components: {},
    data() {
        return {
            allSelected: [],
            selected: [],
        };
    },
    computed: {
        ...mapState({
            dtoPackage: ({ activationCode }) => activationCode.dtoPackage,
            packageStatusType: ({ activationCode }) =>
                activationCode.packageStatusType,
            subjectPackageList: ({ activationCode }) =>
                activationCode.subjectPackageList,
            selectedSubjects: ({ activationCode }) =>
                activationCode.unitsAndSubjects,
        }),
        changeValCheck() {
            return !this.dtoPackage.isHidden;
        },
    },
    methods: {
        toggleChapter(event, subjectIndex, unitId, chapterId) {
            const subject = this.dtoPackage.subjectUnitsChapters[subjectIndex];
            const unit = subject.units.find((u) => u.id === unitId);
            const chapter = unit.chapters.find((c) => c.id === chapterId);

            chapter.selected = event.target.checked;
        },

        submit() {
            this.$refs.packageForm.validate().then((succ) => {
                if (succ) {
                    this.$store.commit("FILTER_SELECTED_SUBJECTS");

                    const selectedSubjects =
                        this.dtoPackage.subjectUnitsChapters
                            .map((subject) => {
                                const selectedUnits = subject.units
                                    .filter((unit) => unit.selected)
                                    .map((unit) => ({
                                        ...unit,
                                        chapters: unit.chapters.filter(
                                            (chapter) => chapter.selected
                                        ),
                                    }));

                                const selectedChapters = subject.units.flatMap(
                                    (unit) =>
                                        unit.chapters
                                            .filter(
                                                (chapter) => chapter.selected
                                            )
                                            .map((chapter) => ({
                                                chapterId: chapter.id,
                                                unitId: unit.id,
                                            }))
                                );

                                return {
                                    subjectId: subject.selected
                                        ? subject.subjectId
                                        : null,
                                    unitIds: selectedUnits.map(
                                        (unit) => unit.id
                                    ),
                                    chapterIds: selectedChapters.map(
                                        (chapter) => chapter.chapterId
                                    ),
                                };
                            })
                            .filter(
                                (subject) =>
                                    subject.subjectId ||
                                    subject.unitIds.length > 0 ||
                                    subject.chapterIds.length > 0
                            );

                    this.addPackage({
                        ...this.dtoPackage,
                        subjectUnitsChapters: selectedSubjects,
                    });
                    console.log(selectedSubjects, "selectedSubjects");
                    this.$store.commit("RESET_PACKAGE_DTO");
                }
            });
        },

        toggleSubject(event, index) {
            const subject = this.dtoPackage.subjectUnitsChapters[index];
            subject.selected = event.target.checked;
            subject.units.forEach((unit) => {
                unit.selected = event.target.checked;
                if (unit.chapters) {
                    unit.chapters.forEach((chapter) => {
                        chapter.selected = event.target.checked;
                    });
                }
            });
        },

        toggleUnit(event, subjectIndex, unitId) {
            const subject = this.dtoPackage.subjectUnitsChapters[subjectIndex];
            const unit = subject.units.find((u) => u.id === unitId);
            unit.selected = event.target.checked;

            if (!unit.selected) {
                subject.selected = false;
            }
        },

        editPackage() {
            this.$store.commit("FILTER_SELECTED_SUBJECTS");

            const selectedSubjects = this.dtoPackage.subjectUnitsChapters
                .map((subject) => {
                    const selectedUnits = subject.units
                        .filter((unit) => unit.selected)
                        .map((unit) => ({
                            ...unit,
                            chapters: unit.chapters.filter(
                                (chapter) => chapter.selected
                            ),
                        }));

                    const selectedChapters = subject.units.flatMap((unit) =>
                        unit.chapters
                            .filter((chapter) => chapter.selected)
                            .map((chapter) => ({
                                chapterId: chapter.id,
                                unitId: unit.id,
                            }))
                    );

                    return {
                        subjectId: subject.selected ? subject.subjectId : null,
                        unitIds: selectedUnits.map((unit) => unit.id),
                        chapterIds: selectedChapters.map(
                            (chapter) => chapter.chapterId
                        ),
                    };
                })
                .filter(
                    (subject) =>
                        subject.subjectId ||
                        subject.unitIds.length > 0 ||
                        subject.chapterIds.length > 0
                );

            this.updatePackage({
                ...this.dtoPackage,
                subjectUnitsChapters: selectedSubjects,
            });
        },

        backBtn() {
            this.$router.push("/admin/activationCode");
        },

        delBtn(id) {
            this.deletePackage(id);
        },

        setDtoSubjects() {
            this.$store.commit("SET_DTO_SUBJECTS");
        },

        ...mapActions([
            "addPackage",
            "getPackageById",
            "updatePackage",
            "deletePackage",
            "deletePackages",
            "getSubjectPackage",
        ]),
    },

    created() {
        if (this.id) {
            this.getSubjectPackage().then(() => {
                this.getPackageById(this.id).then((dto) => {
                    this.$store.commit(
                        "SET_DTO_SUBJECTS",
                        dto.subjectUnitsChapters
                    );
                });
            });
        } else {
            this.getSubjectPackage().then(() => {
                this.$store.commit("SET_DTO_SUBJECTS");
            });
        }
    },

    beforeDestroy() {
        this.$store.commit("RESET_PACKAGE_DTO");
    },
};
</script>

<style lang="scss">
.package-details {
    .card-footer {
        border: none !important;
    }

    .custom-check-box {
        position: absolute;
        left: -10px;
        top: 15px;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: 1s;
    }

    .units-checkbox-container {
        gap: 20px;
    }

    .collapse.show {
        z-index: 1;

        .custom-check-box {
            opacity: 1;

            visibility: visible;

            display: block;
        }
    }
}
</style>
